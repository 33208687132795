import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const loginPageES = require("./locales/es/loginPage.json");
const loginPagePT = require("./locales/pt/loginPage.json");
const loginPageEN = require("./locales/en/loginPage.json");
const loadingES = require("./locales/es/loading.json");
const loadingPT = require("./locales/pt/loading.json");
const loadingEN = require("./locales/en/loading.json");
const navES = require("./locales/es/navbar.json");
const navPT = require("./locales/pt/navbar.json");
const navEN = require("./locales/en/navbar.json");
const centerES = require("./locales/es/centerContent.json");
const centerPT = require("./locales/pt/centerContent.json");
const centerEN = require("./locales/en/centerContent.json");
const preditivaES = require("./locales/es/preditivaPage.json");
const preditivaPT = require("./locales/pt/preditivaPage.json");
const preditivaEN = require("./locales/en/preditivaPage.json");
const LANG = "pt";

// se tiver mais paginas, translations: { ...musicoPt, ...testePt },

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      pt: {
        translations: {
          ...loginPagePT,
          ...navPT,
          ...centerPT,
          ...preditivaPT,
          ...loadingPT,
        },
      },
      es: {
        translations: {
          ...loginPageES,
          ...navES,
          ...centerES,
          ...preditivaES,
          ...loadingES,
        },
      },
      en: {
        translations: {
          ...loginPageEN,
          ...navEN,
          ...centerEN,
          ...preditivaEN,
          ...loadingEN,
        },
      },
    },
    lng: LANG,
    fallbackLng: LANG,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
