import React from "react";

//pdf
import {
  StyleSheet,
  Page,
  Text,
  View,
  Document,
  Image,
} from "@react-pdf/renderer";
//img
import neuron from "../../img/neuron_logo_color.png";

const styles = StyleSheet.create({
  page: {
    padding: "0.5cm",
  },
  title: {
    textAlign: "center",
    backgroundColor: "#7e2e32",
    color: "#ffffff",
    padding: "0.1cm",
  },
  titleReverse: {
    textAlign: "center",
    backgroundColor: "#fff",
    color: "#7e2e32",
    padding: "0.1cm",
  },
  viewBorder: {
    border: "0.05cm solid black",
  },
  equalColumns: {
    flexDirection: "row",
    width: "100%",
  },
  columns: {
    flexDirection: "column",
    width: "100%",
  },
  image_logo: {
    maxWidth: "2.2cm",
    maxHeight: "2.2cm",
    marginLeft: "0.1cm",
    flexGrow: "1",
  },
});

const GeneratePdf = ({ recivedData }) => {
  let data_programada = recivedData.data_manutencao?.split("-") ?? "";
  data_programada =
    `${data_programada[2]}/${data_programada[1]}/${data_programada[0]}` || "";

  let data_inicio = recivedData.data_inicio_reparo?.split("T")[0];
  let hora_inicio = recivedData.data_inicio_reparo?.split("T")[1];
  if (recivedData.data_inicio_reparo) {
    data_inicio = data_inicio.split("-");
    data_inicio = `${data_inicio[2]}/${data_inicio[1]}/${data_inicio[0]}`;

    hora_inicio = hora_inicio.split(".")[0];
  }

  let data_final = recivedData.data_finalizacao_reparo?.split("T")[0];
  let hora_final = recivedData.data_finalizacao_reparo?.split("T")[1];

  if (recivedData.data_finalizacao_reparo) {
    data_final = data_final.split("-");
    data_final = `${data_final[2]}/${data_final[1]}/${data_final[0]}`;

    hora_final = hora_final.split(".")[0];
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={[styles.titleReverse, styles.viewBorder, styles.equalColumns]}
        >
          <Image src={neuron} style={styles.image_logo} />
          <View
            style={{
              flexGrow: "4",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Ordem de Serviço</Text>
          </View>
        </View>
        <View style={styles.viewBorder}>
          <Text style={styles.title}>Equipamento</Text>
          <Text style={{ padding: "0.1cm" }}>
            {recivedData.equipamento.nome}
          </Text>
        </View>
        <View style={styles.viewBorder}>
          <Text style={styles.title}>Tag</Text>
          <Text style={{ padding: "0.1cm" }}>
            {recivedData.equipamento.tag}
          </Text>
        </View>
        <View style={styles.equalColumns}>
          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
            <Text style={styles.title}>ID</Text>
            <Text style={{ padding: "0.1cm" }}>{recivedData.id}</Text>
          </View>
          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
            <Text style={styles.title}>Data programada</Text>
            <Text style={{ padding: "0.1cm" }}>
              {data_programada || "Não definida"}
            </Text>
          </View>
          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
            <Text style={styles.title}>Horário programado</Text>
            <Text style={{ padding: "0.1cm" }}>
              {recivedData?.horario_manutencao || "Não definido"}
            </Text>
          </View>

          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
            <Text style={styles.title}>Criticidade</Text>
            <Text style={{ padding: "0.1cm" }}>
              {recivedData.equipamento.criticidade.nivel}
            </Text>
          </View>
        </View>

        <View>
          {recivedData.osProcedimento.map((proc, keyProc) => {
            return (
              <View>
                <View
                  style={{
                    width: "100%",
                    marginTop: "0.15cm",
                  }}
                ></View>
                <View style={styles.columns}>
                  <View style={[styles.viewBorder]}>
                    <Text style={styles.title}>Procedimento {keyProc + 1}</Text>
                    <Text style={{ padding: "0.1cm" }}>
                      {proc.procedimento.nome}
                    </Text>
                  </View>
                  <View style={[styles.viewBorder]}>
                    <Text style={styles.title}>Profissional</Text>
                    <Text style={{ padding: "0.1cm" }}>
                      {proc.profissional.nome}
                    </Text>
                  </View>
                  <View style={[styles.viewBorder]}>
                    <Text style={styles.title}>Profissional Substituto</Text>
                    <Text style={{ padding: "0.1cm" }}>
                      {proc.profissionalSubstituto.nome}
                    </Text>
                  </View>
                </View>

                <View>
                  <View style={[styles.viewBorder, styles.equalColumns]}>
                    <Text
                      style={[
                        styles.title,
                        {
                          minWidth: "4.2cm",
                        },
                      ]}
                    >
                      Epi(s)
                    </Text>
                    <View style={{}}>
                      {proc.procedimento.procedimentoEpi.map((epi) => {
                        return (
                          <Text style={{ padding: "0.1cm" }}>
                            - {epi.epi.nome}
                          </Text>
                        );
                      })}
                    </View>
                  </View>
                  <View style={[styles.viewBorder, styles.equalColumns]}>
                    <Text
                      style={[
                        styles.title,
                        {
                          minWidth: "4.2cm",
                        },
                      ]}
                    >
                      Ferramenta(s)
                    </Text>
                    <View style={{}}>
                      {proc.procedimento.procedimentoFerramenta.map(
                        (ferramenta) => {
                          return (
                            <Text style={{ padding: "0.1cm" }}>
                              - {ferramenta.ferramenta.nome}
                            </Text>
                          );
                        }
                      )}
                    </View>
                  </View>
                </View>

                <View>
                  {proc.procedimento.procedimentoKit.map((kit, key) => {
                    return (
                      <View>
                        <View style={[styles.viewBorder, {}]}>
                          {key === 0 && <Text style={[styles.title]}>Kit</Text>}
                          <Text
                            style={{
                              padding: "0.1cm",
                            }}
                          >
                            {kit.kit.nome}
                          </Text>
                        </View>
                        <View style={styles.equalColumns}>
                          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
                            {key === 0 && (
                              <Text style={[styles.title, {}]}>Peça(s)</Text>
                            )}
                            {kit.kit.pecasKit.map((peca) => {
                              return (
                                <Text
                                  style={{
                                    padding: "0.1cm",
                                    borderBottom: "0.05cm dotted black",
                                  }}
                                >
                                  {peca.peca.nome}
                                </Text>
                              );
                            })}
                          </View>
                          <View style={[styles.viewBorder, { flexGrow: "1" }]}>
                            {key === 0 && (
                              <Text style={[styles.title, {}]}>Quantidade</Text>
                            )}
                            <View>
                              {kit.kit.pecasKit.map((peca) => {
                                return (
                                  <Text
                                    style={{
                                      padding: "0.1cm",
                                      borderBottom: "0.05cm dotted black",
                                    }}
                                  >
                                    {peca.quantidade}
                                  </Text>
                                );
                              })}
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              </View>
            );
          })}
          <View style={styles.viewBorder}>
            <Text style={styles.title}>Observação</Text>
            <Text style={{ padding: "0.1cm" }}>
              {recivedData.observacao
                ? recivedData.observacao.toString()
                : "- SEM OBSERVAÇÃO - "}
            </Text>
          </View>

          <View style={styles.equalColumns}>
            <View style={[styles.viewBorder, { flexGrow: "1" }]}>
              <Text style={styles.title}>Data e hora de inicio do reparo</Text>
              {!recivedData.data_inicio_reparo ? (
                <Text
                  style={{
                    padding: "0.25cm",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  ____/____/____ {"   "}____ : ____
                </Text>
              ) : (
                <Text>
                  {data_inicio}, {hora_inicio}
                </Text>
              )}
            </View>
            <View style={[styles.viewBorder, { flexGrow: "1" }]}>
              <Text style={styles.title}>
                Data e hora de finalização do reparo
              </Text>
              {!recivedData.data_finalizacao_reparo ? (
                <Text
                  style={{
                    padding: "0.25cm",
                    textAlign: "center",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  ____/____/____ {"   "}____ : ____
                </Text>
              ) : (
                <Text>
                  {data_final}, {hora_final}
                </Text>
              )}
            </View>
          </View>
          {!recivedData.data_finalizacao_reparo &&
            !recivedData.data_inicio_reparo && (
              <View style={[styles.viewBorder]}>
                <Text style={styles.title}>Assinatura</Text>
                <Text
                  style={{
                    padding: "0.5cm",
                  }}
                ></Text>
              </View>
            )}
        </View>
      </Page>
    </Document>
  );
};

export default GeneratePdf;
