import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearIcons } from "../../../actions/icons";
import { clearSelectedSideNav } from "../../../actions/selectedSideNav";
import { useMutation } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";
import { refreshToken } from "../../../server/routes/player";
import { sign_in, sign_off } from "../../../actions/login/isLogged";

const TokenRefresh = () => {
  const dispatch = useDispatch();
  const loginSuccess = (resp) => {
    dispatch(
      sign_in(
        resp.data.player.grupo,
        resp.data.tk,
        resp.data.player.id_industria
      )
    );
    localStorage.setItem("tk", resp.data.tk);
  };

  const mutation = useMutation((data) => {
    return axios
      .post(refreshToken, data)
      .then((resp) => {
        loginSuccess(resp);
      })
      .catch((err) => {
        if (err.response.status === 401) loginSuccess(err.response);
      })
      .finally(() => {
        window.location.reload();
      });
  });

  const logOutHandler = () => {
    dispatch(sign_off());
    dispatch(clearIcons());
    dispatch(clearSelectedSideNav());
    localStorage.setItem("logged", "false");
    localStorage.setItem("function", "");
    localStorage.setItem("tk", "");
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("industria", "");
    localStorage.setItem("innerNav", "c1");
    localStorage.setItem("refreshTokenTime", "");
    localStorage.setItem("refreshLongerTokenTime", "");
    window.dispatchEvent(new Event("changeLogin"));
    toast.info("Por favor faça seu login novamente");
  };

  //RefreshToken 8 hours and logout 20 days
  useEffect(() => {
    const refreshVerificationInterval = setInterval(() => {
      let timeDifferenceToken =
        new Date().getTime() -
        parseInt(localStorage.getItem("refreshTokenTime"));
      let timeDifferenceTokenExpiration =
        new Date().getTime() -
        parseInt(localStorage.getItem("refreshLongerTokenTime"));

      const refreshTokenInterval = 28800000;
      const expirationRefreshToken = 1728000000;
      if (timeDifferenceToken > refreshTokenInterval) {
        mutation.mutate({ refresh_tk: localStorage.getItem("refreshToken") });
        localStorage.setItem("refreshTokenTime", new Date().getTime());
        console.log("token refresh");
      }

      if (timeDifferenceTokenExpiration > expirationRefreshToken) logOutHandler();
      if (localStorage.getItem("refreshToken") === undefined) logOutHandler();
    }, 2500);

    return () => {
      clearInterval(refreshVerificationInterval);
    };
  }, []);

  return <></>;
};

export default TokenRefresh;
