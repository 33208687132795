const languageReducer = (state = "pt", action) => {
  switch (action.type) {
    case "LANG_PT":
      return (state = "pt");
    case "LANG_ES":
      return (state = "es");
    case "LANG_EN":
      return (state = "en");
    default:
      return state;
  }
};

export default languageReducer;
