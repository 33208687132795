import React from "react";
import styled, { css } from "styled-components";
//traslation
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { Thing } from "./LoadingUtil";
import { useSelector } from "react-redux";

const Loading = () => {
  const currentLang = useSelector((state) => state.language);

  const { t } = useTranslation();
  let title = t("loading").split("");
  const [titleAnim, setTitleAnim] = useState([]);

  const refreshLanguage = () => {
    title = t("loading").split("");
    setTitleAnim([]);
    title.forEach((e) => {
      setTitleAnim((current) => [
        ...current,
        <span key={e + Math.random()}>{e}</span>,
      ]);
    });
  };

  useEffect(() => {
    refreshLanguage();
  }, [currentLang]);

  useEffect(() => {
    refreshLanguage();
  }, []);

  return (
    <LoadingWrapper>
      <Thing className="loading_animation">
        {titleAnim.map((e) => {
          return e;
        })}
      </Thing>
      <span className="circle"></span>
      <span className="circle"></span>
      <span className="circle"></span>
      <span className="circle"></span>
      <span className="circle"></span>
    </LoadingWrapper>
  );
};

const LoadingWrapper = styled.div`
  max-width: 200px;
  .loading_animation {
    display: flex;
    span {
      z-index: 2;
      display: inline-block;
      animation: bounce 1000ms ease infinite;
    }
  }

  position: relative;
  padding-top: 100px;
  margin: auto;

  .circle {
    position: absolute;
    width: 5px;
    height: 2px;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
    animation-name: slide;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    background-color: var(--clr__secondary);
    bottom: -0.3rem;

    &:nth-child(2) {
      animation-delay: 240ms;
    }
    &:nth-child(3) {
      animation-delay: 480ms;
    }
    &:nth-child(4) {
      animation-delay: 720ms;
    }
    &:nth-child(5) {
      animation-delay: 960ms;
    }
  }

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slide {
    0% {
      left: 0;
      opacity: 0.8;
    }

    100% {
      left: 100%;
      opacity: 0.6;
    }
  }
`;

export default Loading;
