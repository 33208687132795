export const lightTheme = {
  body: "var(--clr__white)",
  centerbody: "var(--clr__white)",
  bodyReverse: "#999",
  text: "var(--clr__black)",
  textReverse: "#eee",
  buttonTop: "var(--clr__white)",
  scrollBarBackground: "#ddd",
  buttonShadow: "#333",
  scrollBar: "var(--clr__primary)",
  scrollBarHover: "var(--clr__secondary)",
  backgroundDestaque: "#dbfcff",
  navbarSide:
    "linear-gradient(182.1deg, var(--clr__icro) 53.71%, var(--clr__icro-dark) 98.23%)",
  navbarTop:
    "linear-gradient(90deg, var(--clr__icro) 7.49%, var(--clr__icro-dark) 95.15%)",
  colorIcro: "var(--clr__icro)",
  shadow: "rgba(50 50 50 /0.35)",
  lightShadow: " #eee",
  placeholderText: "#666",
  shadowSupport: "rgba(155, 155, 155, 0.5)",
};
export const darkTheme = {
  body: "#333",
  centerbody: "#666",
  bodyReverse: "var(--clr__white)",
  text: "#eee",
  textReverse: "var(--clr__black)",
  buttonTop: "#ddd",
  scrollBarBackground: "#f1f1f1",
  buttonShadow: "#777",
  scrollBar: "#888",
  scrollBarHover: "#555",
  backgroundDestaque: "#4d5859",
  navbarSide: "var(--clr__black)",
  navbarTop:
    "linear-gradient(90deg, var(--clr__black) 65.71%, var(--clr__dark__gray) 95.15%)",
  colorIcro: "var(--clr__white)",
  shadow: "rgba(150 150 150 /0.25)",
  lightShadow: " #444",
  placeholderText: "#bbb",
  shadowSupport: "rgba(50, 50, 50, 0.5)",
};
