import { createGlobalStyle } from "styled-components";
import "../css/anim.scss";

const GlobalStyles = createGlobalStyle`

    :root{
        --clr__icro: #770028;
        --clr__icro-dark: #641a33;
        --clr__primary: #0A9DC4;
        --clr__secondary: #0C6178;
        --clr__third: #C0D8FD;
        --clr__light__gray:#ccc;
        --clr__medium__gray:#9E989B;
        --clr__dark__gray: #212529;
        --clr__white:#fff;
        --clr__black: #0c0c0c;
        --clr__red: #ee1111;
        --clr__red-hover: #881111;

        --shadow-box: 0px 0px 5px ${({ theme }) => theme.shadow};
    }
    html{
        overflow: hidden;

        @media screen and (max-height: 500px) {
            overflow-y: auto !important;
        }
    }
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    body{
        background: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        font-family: 'sansation','Ubuntu',sans-serif;
    }

    html * {
        font-family: 'sansation','Ubuntu',sans-serif;
    }

    body,p,a,span,h1,h2,h3,h4, textarea{
        font-family: 'sansation','Ubuntu',sans-serif;
        width: 100%;
    }
    h1{
    }
    h2{
        font-size: clamp(1rem, 5vw, 3rem);
        font-weight:lighter;
        color: ${({ theme }) => theme.text};
    }
    h3{
        font-size: clamp(1rem,2vw ,1.5rem);
    }
    p{
    }
    a{
        text-decoration:none;
    }
    input,textarea{
        border:1px solid var(--clr__medium__gray);
    }

    span > img.smallSizeImg{
        top:25%;
        width: 20px;
        @media (max-width: 850px) {
            width: 18px;
        }
    }

    .rotateArrow{
        margin-top:0.8rem;
        transform: rotateX(180deg);
    }

    .rotate{
        transform: rotateX(180deg);
    }

    .darkModeSelect{
        color: white;
    background: black;
    }

    .lightModeSelect{
        color: black;
        background: white;
    }

    .hide{
        display: none; 
    }

    .smallSideBar{
        width: 4rem !important;
        nav{
            align-items: center;
            div{
                div{
                    justify-content: center; /* last icon center */
                    margin-right:0;
                }
            }
            nav{
                max-height:350px;
                overflow-y:auto;
                @media(min-height: 850px){
                    max-height:100%;
                }
            }
        }
        div{
            width:100% ;
        }
    }


.reverse_anim {
  animation: show_rev 250ms ease !important;
}

@keyframes show_rev {
  0% {
    bottom: 0;
    opacity: 1;
  }
  100% {
    bottom: -10rem;
    opacity: 0;
  }
}
@keyframes show {
  0% {
    bottom: -10rem;
    opacity: 0.3;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}


.react-date-picker__wrapper{
        padding:0.2rem;
    }
    .react-daterange-picker__wrapper {
        input, svg > *{
        color:${({ theme }) => theme.text};
        stroke:${({ theme }) => theme.text};
        }
    }

    ::-webkit-scrollbar {
    width: 10px;
    height:10px ;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background: ${({ theme }) => theme.scrollBarBackground};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({ theme }) => theme.scrollBar};
    &:hover {
      background-color: ${({ theme }) => theme.scrollBarHover};
    }
  }
`;

export default GlobalStyles;
