import React, { useEffect, Suspense } from "react";
import GlobalStyles from "./components/GlobalStyles";
import { Routes, Route, Navigate } from "react-router-dom";
import ConnectToWallet from "./components/blockchain/ConnectToWallet";
import Loading from "./components/misc/loading/Loading";
import Pdf from "./pages/Pdf";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import "./css/Global.css";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TokenRefresh from "./components/misc/tokenRefresh/TokenRefresh";
import { sign_in } from "./actions/login/isLogged";

const Login = React.lazy(() => import("./pages/Login"));
const Preditiva = React.lazy(() => import("./pages/Preditiva"));
const Manutencao = React.lazy(() => import("./pages/Manutencao"));
const Producao = React.lazy(() => import("./pages/Producao"));
const Fornecedor = React.lazy(() => import("./pages/Fornecedor"));
const Admin = React.lazy(() => import("./pages/Admin"));
const Almoxarifado = React.lazy(() => import("./pages/Almoxarifado"));

const App = () => {
  const dispatch = useDispatch();
  const [isLogged, setIsLogged] = useState("");
  const walletAddressRequest = useSelector((state) => state.wallet);
  let walletAddress = "1";
  const [who, setWho] = useState("");
  const [tk, setTk] = useState("");
  const [industria, setIndustria] = useState("");

  const theme = useSelector((state) => state.darkMode);

  const queryClient = new QueryClient();
  if (walletAddressRequest !== "") {
    walletAddress = walletAddressRequest;
  }

  const loginOptions = [
    "administrador",
    "preditiva",
    "manutencao",
    "producao",
    "fornecedor",
    "almoxarifado",
  ];

  const verifyLocalStorage = () => {
    let loginOption = localStorage.getItem("function");
    if (!loginOptions.includes(loginOption)) {
      localStorage.setItem("logged", false);
      localStorage.setItem("function", "");
      localStorage.setItem("tk", "");
    }
    if (
      localStorage.getItem("logged") === null ||
      localStorage.getItem("logged") === "false"
    ) {
      setIsLogged(false);
    } else if (localStorage.getItem("logged") === "true") {
      setIsLogged(true);
      setWho(localStorage.getItem("function"));
      setTk(localStorage.getItem("tk"));
      setIndustria(localStorage.getItem("industria"));
      dispatch(
        sign_in(
          localStorage.getItem("function"),
          localStorage.getItem("tk"),
          localStorage.getItem("industria")
        )
      );
    }
  };

  window.addEventListener("changeLogin", () => {
    verifyLocalStorage();
  });

  useEffect(() => {
    verifyLocalStorage();
  }, []);

  const verifyNameUrl = () => {
    const nameUrl = window.location.href.split("/");
    if (nameUrl.includes("pdf")) {
      return true;
    } else {
      return false;
    }
  };

  const isDarkMode = !theme ? "light" : "dark";

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={isDarkMode}
      />
      {isLogged && <TokenRefresh />}
      <ThemeProvider theme={!theme ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Suspense fallback={<Loading />}>
          {process.env.REACT_APP_USE_BLOCKCHAIN === "true" && isLogged && (
            <ConnectToWallet />
          )}
          <Routes>
            {!isLogged && <Route path="/" element={<Login />} />}
            {isLogged && walletAddress !== "" && (
              <>
                {who === "preditiva" && (
                  <Route path="/preditiva" element={<Preditiva />} />
                )}
                {who === "administrador" && walletAddress !== "" && (
                  <Route path="/administrador" element={<Admin />} />
                )}
                {who === "manutencao" && (
                  <Route path="/manutencao" element={<Manutencao />} />
                )}
                {who === "fornecedor" && (
                  <Route path="/fornecedor" element={<Fornecedor />} />
                )}

                {who === "producao" && (
                  <Route path="/producao" element={<Producao />} />
                )}
                {who === "almoxarifado" && (
                  <Route path="/almoxarifado" element={<Almoxarifado />} />
                )}
                <Route path="/pdf/*" element={<Pdf />} />
              </>
            )}
            {verifyNameUrl() !== true && (
              <Route
                path="*"
                element={<Navigate to={isLogged ? `/${who}` : "/"} />}
              />
            )}{" "}
          </Routes>
        </Suspense>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} position="top-right" />
    </QueryClientProvider>
  );
};

export default App;
