const ROUTE = process.env.REACT_APP_ROUTE_URL;

export const createOS = `${ROUTE}api/os`;
export const getOs = `${ROUTE}api/os`;
export const osRelaunch = `${ROUTE}api/os/relancamento`;
export const declineOs = `${ROUTE}api/os/recusa`;
export const finishOs = `${ROUTE}api/os/conclusao`;
export const osProductionConsensus = `${ROUTE}api/os/consenso`;
export const osMaintenanceConsensus = `${ROUTE}api/os/consenso`;
export const osMaintenanceViwed = `${ROUTE}api/os/manutencao`;
export const osProductionViwed = `${ROUTE}api/os/producao`;
export const osSuplier = `${ROUTE}api/os/fornecedor`;
export const osMaintenance = `${ROUTE}api/os/manutencao`;
export const osRepair = `${ROUTE}api/os/reparo`;
export const osMaintenanceClosure = `${ROUTE}api/os/fechamento-manutencao`;
export const osClosurePredictive = `${ROUTE}api/os/fechamento-preditiva`;
export const getOsQuantity = `${ROUTE}api/os/fases`;
export const getOsIndicators = `${ROUTE}api/os/indicator`;
export const getOsIndicatorsByPeriod = `${ROUTE}api/os/indicator`;
export const osPdfGenerate = `${ROUTE}api/os/pdf`;
export const getOsQtdSoundAlert = `${ROUTE}api/os/count`;
